@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .main-padding {
    @apply p-5 xsm:p-8;
  }

  * {
    @apply m-0 p-0 box-border transition-all;
    font-family: Inter;
  }
}

@layer components {
  .dashboard-card-container {
    @apply border border-solid border-grey/100 rounded-lg w-full hover:bg-secondary/blue/50 hover:border-secondary/blue/500;
  }

  .dashboard-card-container-active {
    @apply border border-solid  rounded-lg w-full bg-secondary/blue/50 border-secondary/blue/500;
  }

  .form-label {
    @apply font-normal text-xs leading-5 text-shades/black mb-[2px] block;
  }

  .wrapper {
    @apply px-6 sm:px-14 lg:px-[100px] mx-auto;
  }
  input {
    @apply outline-none border-none disabled:cursor-not-allowed;
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    @apply text-neutral/400 opacity-100 !important;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    @apply text-neutral/400 opacity-100 !important;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    @apply text-neutral/400 opacity-100 !important;
  }

  .modal-heading {
    @apply text-base font-bold leading-[19px] mt-6 mb-6 text-shades/black;
  }

  .label {
    @apply flex gap-x-6 flex-col xxs:flex-row gap-y-2;
  }

  /* not role checkbox. */
  .markdown-editor ul li,
  .program-markdown ul li {
    @apply list-disc list-inside;
  }

  .markdown-editor ul li[role='checkbox'],
  .program-markdown ul li[role='checkbox'] {
    @apply list-none list-inside ml-2;
  }

  .markdown-editor ol li,
  .program-markdown ol li {
    @apply list-decimal list-inside;
  }

  .program-markdown strong {
    @apply font-semibold;
  }

  .program-markdown p {
    @apply my-6;
  }
}

@layer utilities {
}

div.Toastify {
  @apply z-[20000000] relative;
}

.apply-link:hover img {
  /* animation that makes the img (an arrow) move to the right and back.  */
  @apply animate-ping;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

.bg-image {
  background-repeat: no-repeat important;
  background-size: cover !important;
  background-position: center !important;
  height: 100vh;
  width: 100%;
  opacity: 0;
  transition: opacity 6s ease-in-out;
  z-index: 0;
}

.bg-image.visible {
  opacity: 1;
  z-index: 1;
}

.bg-image.previous {
  opacity: 0.5;
}

.arrow-bounce {
  animation: bounce 2s infinite;
}

* {
  scroll-behavior: smooth;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
